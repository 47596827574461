<template>
    <div>
        <el-main style="padding: 0px">
            <el-button size="mini" @click="openUpgradeDialog" type="danger">远程升级</el-button>
            <el-dialog title="提示" id="upgradeDialog" :visible="upgradeDialogVisible" width="30%"
                       @close='upgradeDialogVisible = false' :close-on-click-modal="false" :modal-append-to-body="false" append-to-body top="30vh">
                <span>这项操作会将设备 [ {{deviceNumber}} ] 的软件升级到最新，是否继续执行？</span>
                <span slot="footer" class="dialog-footer">
                        <el-button @click="upgradeDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="cmdUpgradeSend">确 定</el-button>
                    </span>
            </el-dialog>
        </el-main>
    </div>
</template>

<script>
import {getToken} from "@/api/auth";
import websocket from "@/utils/websocket";
import {deviceUpgrade} from "@/api/station/deviceInfoApi";
import loading from "@/utils/cmdLoading";
import message from "@/utils/message";
export default {
    name: "device-upgrade",
    props: {

        deviceId: {

            type: Number
        },
        deviceNumber: {

            type: String
        }
    },
    data() {

        return {

            upgradeDialogVisible: false,
            topic: "/user/" + getToken() + "/deviceUpgrade",
            cmdLoading: null
        }
    },
    methods: {

        // 初始化页面
        initPage() {

            this.unSub();
            websocket.initWebSocket(this.topic, this.wsCallback);
        },
        wsCallback() {

            if (this.cmdLoading) {

                this.cmdLoading.close();
            }
            message.success("升级指令下发成功");
        },
        // 打开远程升级弹窗
        openUpgradeDialog() {

            this.upgradeDialogVisible = true;
        },
        // 升级执行下发
        cmdUpgradeSend() {

            let param = {

                deviceNum: this.deviceNumber,
                deviceWholeId : this.deviceId + ""
            };
            deviceUpgrade({message: JSON.stringify(param)}).then((res) => {

                if (res.code === '100') {

                    this.cmdLoading = loading.loading("升级指令下发中...", 60);
                } else {

                    message.error(res.msg);
                }
            }).catch(err => console.error(err));
        },
        unSub() {

            websocket.unSubTopic(this.topic);
        }
    }
}
</script>